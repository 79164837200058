/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

@import "fonts.css";
@import "backgrounds.css";

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;


mark {
  background-color: #8ed6f236;
}

@media (prefers-color-scheme: dark){
  mark {
    background-color: #bdf8ff38;
    color: white;
  }
}