@font-face {
  font-family: 'Bespoke Serif';
  src: url('/fonts/BespokeSerif-Variable.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
}

@font-face {
  font-family: 'Bespoke Serif';
  src: url('/fonts/BespokeSerif-VariableItalic.ttf') format('truetype');
  font-style: italic;
  font-display: swap;
  font-weight: 100 900;
}

@font-face {
  font-family: 'General Sans';
  src: url('/fonts/GeneralSans-Variable.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
}

@font-face {
  font-family: 'General Sans';
  src: url('/fonts/GeneralSans-VariableItalic.ttf') format('truetype');
  font-style: italic;
  font-display: swap;
  font-weight: 100 900;
}